<template>
  <a-menu
      :default-selected-keys="selectedKeys"
      :defaultOpenKeys="openedKeys"
      mode="inline"
      @click="handleClick"
  >
    <template v-for="item in tree">
      <a-menu-item v-if="!item.children" :key="item.id">
        <a-icon style="margin-right: 8px; font-size: 12px" :type="item['icon-type']"/>
        <span style="font-weight: 500; font-size: 14px">{{ item.name }}</span>
      </a-menu-item>
      <a-sub-menu v-else :key="item.id">
        <div slot="title">
          <span style="font-weight: 500; font-size: 14px">{{ item.name }}</span>
        </div>
        <a-menu-item v-for="childItem in item.children" :key="childItem.id">
          <span style="font-weight: 500; font-size: 14px">{{ childItem.name }}</span>
        </a-menu-item>
      </a-sub-menu>
    </template>
  </a-menu>
</template>


<script>

export default ({
  props: {
    tree: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedKeys: {
      type: Array,
      default: () => {
        return []
      }
    },
    openedKeys: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentNode: {},
    }
  },
  methods: {
    handleClick(node) {
      this.currentNode = node
      this.$emit('menuChange', node)
    }
  }
})

</script>


<style scoped lang="scss">

</style>